<template>
	<div>
		<p>点赞标签</p>
		<div v-loading="tagLoading">
			<el-tag v-for="(v,i) in likesignList" :key="v.id" type="success" closable :disable-transitions="false"
				@close="delLikesign(v)">
				{{v.name}}
			</el-tag>
			<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" maxlength="6"
				size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
			</el-input>
			<el-button class="button-new-tag" v-if="likesignList.length<6&&!inputVisible" size="small"
				@click="showInput">+
				添加新标签</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tagLoading: false,
				inputValue: "",
				likesignList: [],
				inputVisible: false,
			}
		},
		created() {
			this.getLikesignList()
		},
		methods: {
			showInput() {
				this.inputVisible = true;
				this.$nextTick((_) => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},
			handleInputConfirm() {
				if (this.likesignList.length >= 6) {
					this.$message.warning("最多能创建6个点赞标签")
					return
				}
				this.addLikesign()
				this.inputVisible = false;
				this.inputValue = "";
			},
			// 获取点赞列表
			getLikesignList() {
				this.tagLoading = true;
				this.$get('/platform-config/likesign/list').then(res => {
					this.tagLoading = false;
					if (res && res.code == 0) {
						this.likesignList = res.data
					}
				})
		},
		// 新增点赞标签
		addLikesign() {
			if (!this.inputValue) {
				this.$message.warning("标签名称不能未空！~");
				return
			}
			let param = {
				name: this.inputValue
			}
			this.tagLoading = true;
			this.$post('/platform-config/likesign', param).then(res => {
				this.tagLoading = false;
				if (res && res.code == 0) {
					this.$message.success("新增成功！");
					this.getLikesignList()
				}
			})
	},
	// 删除点赞标签
	delLikesign(item) {
		this.tagLoading = true;
		this.$del('/platform-config/likesign', [item.id]).then(res => {
			this.tagLoading = false;
			if (res && res.code == 0) {
				this.$message.success("删除成功！");
				this.getLikesignList()
			}
		})
	}
	}
	}
</script>

<style scoped lang="scss">
	p {
		line-height: 36px;
		height: 36px;
		color: #409eff;
		font-weight: bold;
	}

	.mt30 {
		margin-top: 30px;
	}

	br {
		line-height: 30px;
	}

	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}
</style>
